@keyframes move_wave {
    0% {
        transform: translateX(0) translateZ(0) scaleY(1)
    }
    50% {
        transform: translateX(-25%) translateZ(0) scaleY(0.55)
    }
    100% {
        transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}

.backgroundLinear {
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(235,97,32,1) 100%, rgba(8,35,49,1) 100%, rgba(0,224,255,1) 100%, rgba(121,9,9,1) 100%);
}

.waveWrapper {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
}
.waveWrapperInner {
    position: absolute;
    width: 100%;
    overflow: hidden;
    height: 25%;
    bottom: -1px;
    z-index: 1;
}
.bgTop {
    z-index: 15;
    opacity: 0.5;
}
.bgMiddle {
    z-index: 10;
    opacity: 0.75;
}
.bgBottom {
    z-index: 5;
}
.wave {
    position: absolute;
    left: 0;
    width: 200%;
    height: 100%;
    background-repeat: repeat no-repeat;
    background-position: 0 bottom;
    transform-origin: center bottom;
}
.waveTop {
    background-size: 50% 100px;
}
.waveAnimation .waveTop {
  animation: move-wave 3s;
   -webkit-animation: move-wave 3s;
   -webkit-animation-delay: 1s;
   animation-delay: 1s;
}
.waveMiddle {
    background-size: 50% 120px;
}
.waveAnimation .waveMiddle {
    animation: move_wave 10s linear infinite;
}
.waveBottom {
    background-size: 50% 100px;
}
.waveAnimation .waveBottom {
    animation: move_wave 15s linear infinite;
}

.test{
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(235,97,32,1) 100%, rgba(8,35,49,1) 100%, rgba(0,224,255,1) 100%, rgba(121,9,9,1) 100%);

}

.orange{
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(235,97,32,1) 100%, rgba(8,35,49,1) 100%, rgba(0,224,255,1) 100%, rgba(121,9,9,1) 100%);
}
