.container {
  text-align: center;
  overflow-x: hidden;
  
}
.limit{
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
line-height: 14px; /* fallback */
max-height: 90px; /* fallback */
-webkit-line-clamp: 1; /* number of lines to show */
-webkit-box-orient: vertical;
height: 15px;
}

.filtre{
  background: linear-gradient(86deg, rgba(227,95,32,1) 16%, rgba(240,131,33,1) 100%);
}

.rotate90 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
