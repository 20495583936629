
corps {
text-align: left;
}

corps p {
  font-size: 1.1rem;
  margin-top: 1.2rem;
}

corps h3 {
  margin-top: 3rem;
}
