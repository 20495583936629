@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  overflow-x: hidden;
  background-color:#F6F7FB;
}

.navbar-light .navbar-toggler-icon {
  width: 30px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

:root{

}

.height{
  min-height: 100vh;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.screen:-webkit-scrollbar {
  display: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
} 

.display-b19-logo{
  width: 100px;
}

.display-none {
  display: contents;
}

.display-news-ipad {
  background-color: #e2e2e2;
}

.display-logo-b19-home-page{
  display: content;
}

.NavLogoB19 {
  display: content;
}

.marginRightNav {
  margin-right: 30px;
}

.orange-hover:hover {
  background-color: orange;
}


@media screen and (max-width: 850px) {
  .display-none {
    display: none;
  }
  .display-b19-logo{
    
    display: none;
  }
  .display-news-ipad {
    background-color: #e2e2e2;
  }
  .display-logo-b19-home-page{
    display: content;
    object-fit: contain;
    width: 100px;
    margin-bottom: 40px;
  }
  .NavLogoB19 {
    display: none;
  }
  .Birthday {
    padding-top: 20px;
  }
  .marginRightNav{
    margin-right: 15px;
  }
  .paddingHours{
    padding-top: 25px;
  }
}

@media screen and (min-width: 800px) {
  .display-logo-b19-home-page{
    display: none;
  }

}

@font-face {
  font-family: 'Typold Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Typold Regular'), url('./fonts/The Northern Block Ltd - Typold Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Typold Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Typold Bold'), url('./fonts/The Northern Block Ltd - Typold Bold.woff') format('woff');
    }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.linear{
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(235,97,32,1) 100%, rgba(8,35,49,1) 100%, rgba(0,224,255,1) 100%, rgba(121,9,9,1) 100%);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay{
  box-shadow: inset 0 0 0 100vw rgba(0,0,0,0.6);
  background-size: cover;
  color: white;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video{
  background: #B1B1B1;
border: 10px solid rgba(184, 184, 184, 0.05);
box-shadow: 0px 10px 50px rgba(0, 59, 121, 0.0725798);
border-radius: 20px;
max-width: 100%;
}

.align-me {
  display: flex;
  align-items: center;
  justify-content: center;
}

.backMember{
  background: linear-gradient(270deg, #212529 0%, #082331 100%);
}

.buttonMember{
  background: #FFFFFF;
  border-radius: 10px;
}

.font{
color: #EF7F3C;
}

.vimeo-wrapper {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: -1;
   pointer-events: none;
   overflow: hidden;
}

.vimeo-wrapper iframe {
   width: 100vw;
   height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
   min-height: 100vh;
   min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.overlayG{
  filter: brightness(60%);
  height: 70vh;
  z-index: 1001

}

.front{
  min-height: 100vh;
  text-align: center;
  justify-content: center;
  align-items: center;

  display: flex;

}

.center {
text-align: center
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.topnav {
  background-color: #082432;
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: right;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 13px;
  font-weight: bold;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #082432;
  color: #082432;
}

.active{
  color: transparent;
}

.shadow {
  -webkit-box-shadow: 3px 3px 5px 6px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    3px 3px 5px 6px #ccc;  /* Firefox 3.5 - 3.6 */
  box-shadow:         3px 3px 5px 6px #ccc;
}

.businessCorporateClub {
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 8px;
  font-family: 'Typold Regular';
  margin-top: 25px;
}

.logoB19 {
  margin-top: 50px;
}

.language {
  margin-top: 60px;
}

.bottomPage {
  margin-top: 100px;
  margin-bottom: 20px;
}

.borderLogo {
  border-right-color: rgba(255,255,255,0.2);
}

.test {
  background-color: red;
}

.background {
   padding: 10px;
   color: white;
   background-color: #282c34;
 }

.dark{
  color: rgba(255,255,255,0.75);
  background-color: #E6E6E6;
  border-top-color: rgba(255,255,255,0.15);
 }

 #footer{
   padding-top: 40px;
 }

 .iconlist {
    padding-left: 0;
    margin-left: 0;
    list-style-type: none;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.overButtonSubscribe:hover{
  -webkit-filter: brightness(.5);
  filter: brightness(.5);
}
