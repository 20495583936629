.circle
 {
   z-index: 1001;
 border-radius: 50px;
 height: 100px;
 width: 100px;
 background: linear-gradient(to right, blue 50%, red 50%);
 background-size: 200% 100%;
 background-position: right bottom;
 transition: all 2s ease;
 }


 circle:hover{
   color: white;
   fill: rgba(0,0,0,0.3);
 }

 ellipse:hover{
     color: white;
     fill: rgba(0,0,0,0.3);
 }

.border {
  border: solid;
}

.oval {
  border-radius: 50%;
  height: 150px;
  width: 300px;
  background-color: #18C973;
}
