.Container {
  background-color: blue;
  @media (max-width: 767px) {
    background-color: red;
  }
}

.linearOrange{
  background: linear-gradient(nulldeg, rgba(239,106,31,1) 50%, rgba(255,133,20,1) 100%);
}
