.navbarScroll {
  color: rgba(255,255,255,0.75);
  background: linear-gradient(270deg, #212529 0%, #082331 100%);
  transition: background 500ms;
}

.navbare {
  background: transparent;

}

.widthLogo {
  width: 7vh;
  margin-left: 1vw;
}
